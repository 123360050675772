import React, { useState } from "react"
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
} from "@ant-design/icons"
import { useHistory, Link, useLocation } from "react-router-dom"

import { Layout, Menu, theme, message, Button, Popconfirm, Select } from "antd"
import routes from "../constants/router"
import SubMenu from "antd/es/menu/SubMenu"
import IconFinder from "../constants/icons"
import { language } from "../constants"
import GoogleTranslation from "../component/GoogleTranslation"
const { Header, Sider, Content } = Layout
function MainLayout({ children }) {
  const history = useHistory()
  const location = useLocation()
  const [collapsed, setCollapsed] = useState(false)
  const role = JSON.parse(localStorage.getItem("user"))?.role

  const generateRoleRoutes = (rout) => {
    const generated = rout.filter((el) => el.meta.permission.search(role) >= 0)
    return generated
  }
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  const confirm = (e) => {
    localStorage.removeItem("token")
    history.push("/login")
    message.success("Click on Yes")
  }
  const cancel = (e) => {
    console.log(e)
    // message.error("Click on No")
  }
  function menuGenerator(routes) {
    var renderMenu = generateRoleRoutes(routes)
    return renderMenu?.map((e, i) => {
      if (e?.children && e?.children.length && !e?.noSub) {
        return (
          <SubMenu
            key={e.meta.title}
            icon={IconFinder(e?.meta?.icon)}
            title={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <GoogleTranslation text={e?.meta?.title} />
              </div>
            }
          >
            {generateRoleRoutes(e?.children).map((item, key) => (
              <Menu.Item key={item.path}>
                <Link to={item.path}>
                  <GoogleTranslation text={item.meta.title} />
                </Link>
              </Menu.Item>
            ))}
          </SubMenu>
        )
      } else {
        return (
          <Menu.Item key={e?.path} icon={IconFinder(e?.meta?.icon)}>
            <Link to={e?.path}>
              <GoogleTranslation text={e?.meta?.title} />
            </Link>
          </Menu.Item>
        )
      }
    })
  }

  return (
    <Layout style={{ height: "100vh" }}>
      <Sider
        trigger={null}
        style={{
          padding: 0,
          background: colorBgContainer,
          display: collapsed ? "none" : "block",
        }}
        collapsible
        collapsed={collapsed}
      >
        <div className="logo">
          <img src="/images/smallLoogo.svg" alt="logo" width={25} height={25} />
          {!collapsed && <p>ECO FOREST</p>}
        </div>
        {/* <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={["/"]}
          selectedKeys={location.pathname}
          items={routes.map((item) => ({
            ...item,
            key: item.path,
            icon: IconFinder(item.meta.icon),
            label: item.meta.title,
            to: item.path,
            children: <Link to={item.path}>{item.meta.title}</Link>,
          }))}
        /> */}
        <Menu
          theme="light"
          mode="inline"
          style={{
            overflowY: "auto",
            maxHeight: "90vh",
          }}
          //   onOpenChange={onOpenChange}
          //   openKeys={openKeys}
          //   defaultSelectedKeys={[location.pathname.split("/")[1]]}
          selectedKeys={[location.pathname]}
        >
          {menuGenerator(routes)}
        </Menu>
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingRight: "20px",
            }}
          >
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
                marginRight: 30,
              }}
            />
            <div>
              <Popconfirm
                title=<GoogleTranslation text="Chiqish amali" />
                description=<GoogleTranslation text="Bu vazifaga ishonchingiz komilmi?" />
                onConfirm={confirm}
                onCancel={cancel}
                okText=<GoogleTranslation text="Yes" />
                cancelText=<GoogleTranslation text="No" />
              >
                <Button
                  danger
                  // size="large"
                  icon={<LogoutOutlined />}
                  style={{
                    fontSize: "16px",
                    height: 34,
                  }}
                >
                  <span>
                    <GoogleTranslation text="Chiqish" />
                  </span>
                </Button>
              </Popconfirm>
              <Select
                style={{ width: "130px", marginLeft: "10px" }}
                size="large"
                onChange={(e) => {
                  localStorage.setItem("lang", e)
                  window.location.reload()
                }}
                defaultValue={localStorage.getItem("lang") || "uz"}
                options={language.map((item) => ({
                  label: item.label,
                  value: item.key,
                }))}
              />
            </div>
          </div>
        </Header>
        <Content
          className="content"
          style={{
            minHeight: 280,
            overflowY: "auto",
            background: colorBgContainer,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  )
}

export default MainLayout
