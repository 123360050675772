export const language = [
  { key: "uz", label: "O'zbekcha" },
  { key: "ru", label: "Русский" },
  { key: "en", label: "English" },
]

export const types = {
  1: { label: "Admin", color: "red" },
  2: { label: "Turist", color: "cyan" },
  3: { label: "Tur firma", color: "orange" },
  4: { label: "O'rmon xo'jalik", color: "green" },
}
