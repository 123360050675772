import React, { useEffect, useState } from "react"
// App.js

const GoogleTranslation = ({ text = "No data" }) => {
  const [translation, setTranslation] = useState("")

  useEffect(() => {
    translateText()
  }, [text])

  const translateText = async () => {
    const lang = localStorage.getItem("lang") || "uz"
    if (lang === "uz") {
      setTranslation(text)
      return null
    }
    const apiKey = "AIzaSyDOxHx6QDTjraYPDSglKuxxtI0SAfej-FA"
    const url = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`

    const requestBody = {
      q: text,
      target: localStorage.getItem("lang") || "uz",
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      })

      if (response.ok) {
        const data = await response.json()
        setTranslation(data.data.translations[0].translatedText)
      } else {
        const errorData = await response.json()
        setTranslation(text)
        console.error("Error:", errorData.error.message)
      }
    } catch (error) {
      setTranslation(text)

      console.error("Error:", error)
    }
  }

  return <>{translation}</>
}

export default GoogleTranslation
