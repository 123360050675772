import {
  UnorderedListOutlined,
  UserOutlined,
  SettingOutlined,
  QrcodeOutlined,
  DollarOutlined,
  CarryOutOutlined,
  FundOutlined,
  WechatOutlined,
  EnvironmentOutlined,
  AimOutlined,
  RiseOutlined,
  UsergroupAddOutlined,
  FormOutlined,
  CopyOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons"

function IconFinder(icon) {
  switch (icon) {
    case "UnorderedListOutlined":
      return <UnorderedListOutlined />
    case "UserSwitchOutlined":
      return <UserSwitchOutlined />
    case "RiseOutlined":
      return <RiseOutlined />
    case "UserOutlined":
      return <UserOutlined />
    case "AimOutlined":
      return <AimOutlined />
    case "SettingOutlined":
      return <SettingOutlined />
    case "QrcodeOutlined":
      return <QrcodeOutlined />
    case "TicketIcon":
      return <DollarOutlined />
    case "CarryOutOutlined":
      return <CarryOutOutlined />
    case "FundOutlined":
      return <FundOutlined />
    case "WechatOutlined":
      return <WechatOutlined />
    case "EnvironmentOutlined":
      return <EnvironmentOutlined />
    case "UsergroupAddOutlined":
      return <UsergroupAddOutlined />
    case "FormOutlined":
      return <FormOutlined />
    case "CopyOutlined":
      return <CopyOutlined />
    default:
      break
  }
}
export default IconFinder
