import { lazy } from "react"
//PERMISSIONS
// case admin = 1;
// case user = 2;
// case tour_staff = 3;
// case forestry_staff = 4;
const routes = [
  {
    path: "/",
    component: lazy(() => import("../pages/EcoObjectCategory")),
    meta: {
      title: "Eko obyekt kategoriyalar",
      icon: "UnorderedListOutlined",
      permission: "1",
    },
  },
  {
    path: "/",
    component: lazy(() => import("../pages/Profile")),
    meta: {
      title: "Kabinet",
      icon: "UserSwitchOutlined",
      permission: "3,4",
    },
  },
  {
    path: "/forestry",
    component: lazy(() => import("../pages/Forestry")),
    meta: {
      title: "O'rmon xo'jaligi",
      icon: "EnvironmentOutlined",
      permission: "1",
    },
  },
  {
    path: "/eco-objects",
    component: lazy(() => import("../pages/EcoObject")),
    noSub: true,
    meta: {
      title: "Eko obyektlar",
      icon: "EnvironmentOutlined",
      permission: "1,4",
    },
    children: [
      {
        path: "/eco-objects/:id",
        component: lazy(() => import("../pages/EcoObject/Details")),
        meta: {
          permission: "1,4",
        },
      },
    ],
  },
  {
    path: "/tour",
    component: lazy(() => import("../pages/Tour")),
    noSub: true,
    meta: {
      title: "Tur",
      icon: "FundOutlined",
      permission: "1,3",
    },
    children: [
      {
        path: "/tour/:id",
        component: lazy(() => import("../pages/Tour/Details")),
        meta: {
          permission: "1,3",
        },
      },
    ],
  },
  {
    path: "/excursion",
    noSub: true,
    component: lazy(() => import("../pages/Excursion")),
    meta: {
      title: "Ovlar",
      icon: "AimOutlined",
      permission: "1,4",
    },
    children: [
      {
        path: "/excursion/:id",
        component: lazy(() => import("../pages/Excursion/Details")),
        meta: {
          permission: "1,4",
        },
      },
    ],
  },
  {
    path: "/routes",
    component: lazy(() => import("../pages/EcoObjectCategory")),
    meta: {
      title: "Marshrut",
      icon: "RiseOutlined",
      permission: "1",
    },
    children: [
      // {
      //   path: "/routes/list",
      //   component: lazy(() => import("../pages/Routes/List")),
      //   meta: {
      //     title: "Foydalanuvchi mashrutlari",
      //     icon: "",
      //     permission: "1,3",
      //   },
      // },
      {
        path: "/routes/movements",
        component: lazy(() => import("../pages/Routes/Movements")),
        meta: {
          title: "Harakatlar",
          icon: "",
          permission: "1",
        },
      },
      {
        path: "/routes/placements",
        component: lazy(() => import("../pages/Routes/Placements")),
        meta: {
          title: "Joylashuvlar",
          icon: "",
          permission: "1",
        },
      },
      {
        path: "/routes/visit-reason",
        component: lazy(() => import("../pages/Routes/VisitReason")),
        meta: {
          title: "Tashrif sababi",
          icon: "",
          permission: "1",
        },
      },
      {
        path: "/routes/privileges",
        component: lazy(() => import("../pages/Routes/Privileges")),
        meta: {
          title: "Imtiyozlar",
          icon: "",
          permission: "1",
        },
      },
      {
        path: "/routes/additional-service",
        component: lazy(() => import("../pages/Routes/AdditionalService")),
        meta: {
          title: "Qo'shimcha xizmat",
          icon: "",
          permission: "1",
        },
      },
    ],
  },
  {
    path: "/orders",
    component: lazy(() => import("../pages/Orders/EcoObject/Orders")),
    meta: {
      title: "Buyurtmalar",
      icon: "CopyOutlined",
      permission: "1,3,4",
    },
    children: [
      {
        path: "/orders/eco-objects",
        component: lazy(() => import("../pages/Orders/EcoObject/Orders")),
        meta: {
          title: "Eko Obyektlar",
          icon: "",
          permission: "1,4",
        },
        children: [
          {
            path: "/orders/eco-objects/:id",
            component: lazy(() => import("../pages/Orders/EcoObject/Detail")),
            meta: {
              title: "",
              icon: "",
              permission: "1,3,4",
            },
          },
        ],
      },
      {
        path: "/orders/excursion",
        component: lazy(() => import("../pages/Orders/Excursion/Orders")),
        meta: {
          title: "Ovlar",
          icon: "",
          permission: "1,4",
        },
        children: [
          {
            path: "/orders/excursion/:id",
            component: lazy(() => import("../pages/Orders/Excursion/Detail")),
            meta: {
              title: "",
              icon: "",
              permission: "1,4",
            },
          },
        ],
      },
      {
        path: "/orders/tour",
        component: lazy(() => import("../pages/Orders/Tour/Orders")),
        meta: {
          title: "Turlar",
          icon: "",
          permission: "1,3,4",
        },
        children: [
          {
            path: "/orders/tour/:id",
            component: lazy(() => import("../pages/Orders/Tour/Detail")),
            meta: {
              title: "",
              icon: "",
              permission: "1,3,4",
            },
          },
        ],
      },
      {
        path: "/orders/user-tours",
        component: lazy(() => import("../pages/Orders/UserTour/Orders")),
        meta: {
          title: "Foydalanuvchi turlari",
          icon: "",
          permission: "1,4",
        },
        children: [
          {
            path: "/orders/user-tours/:id",
            component: lazy(() => import("../pages/Orders/UserTour/Detail")),
            meta: {
              title: "",
              icon: "",
              permission: "1,4",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/users",
    component: lazy(() => import("../pages/Users")),
    meta: {
      title: "Moderator va Foydalanuvchilar",
      icon: "UsergroupAddOutlined",
      permission: "1",
    },
  },
  {
    path: "/settings",
    component: lazy(() => import("../pages/EcoObjectCategory")),
    meta: {
      title: "Sozlamalar",
      icon: "SettingOutlined",
      permission: "1",
    },
    children: [
      {
        path: "/setting/countries",
        component: lazy(() => import("../pages/Settings/Countries")),
        meta: {
          title: "Davlatlar",
          icon: "",
          permission: "1",
        },
      },
      {
        path: "/setting/regions",
        component: lazy(() => import("../pages/Settings/Regions")),
        meta: {
          title: "Viloyatlar",
          icon: "",
          permission: "1",
        },
        children: [
          {
            path: "/setting/regions/:id",
            component: lazy(() => import("../pages/Settings/Regions/Detail")),
            meta: {
              permission: "1",
            },
          },
        ],
      },
      {
        path: "/setting/district",
        component: lazy(() => import("../pages/Settings/Districts")),
        meta: {
          title: "Tumanlar",
          icon: "",
          permission: "1",
        },
      },
      {
        path: "/setting/area-types",
        component: lazy(() => import("../pages/Settings/AreaType")),
        meta: {
          title: "Hudud turi",
          icon: "",
          permission: "1",
        },
      },
      {
        path: "/setting/foods",
        component: lazy(() => import("../pages/Settings/Foods")),
        meta: {
          title: "Ovqatlar",
          icon: "",
          permission: "1",
        },
      },
      {
        path: "/setting/transports",
        component: lazy(() => import("../pages/Settings/Transports")),
        meta: {
          title: "Transportlar",
          icon: "",
          permission: "1",
        },
      },
      {
        path: "/setting/languages",
        component: lazy(() => import("../pages/Settings/Languages")),
        meta: {
          title: "Tillar",
          icon: "",
          permission: "1",
        },
      },
      {
        path: "/setting/seasons",
        component: lazy(() => import("../pages/Settings/Seasons")),
        meta: {
          title: "Mavsum",
          icon: "",
          permission: "1",
        },
      },
      {
        path: "/setting/features",
        component: lazy(() => import("../pages/Settings/Features")),
        meta: {
          title: "Xususiyatlar",
          icon: "",
          permission: "1",
        },
      },
      {
        path: "/setting/flora-fauna",
        component: lazy(() => import("../pages/Settings/FloraFauna")),
        meta: {
          title: "Flora fauna",
          icon: "",
          permission: "1",
        },
      },
      {
        path: "/setting/activities",
        component: lazy(() => import("../pages/Settings/Activity")),
        meta: {
          title: "Aktivitilar",
          icon: "",
          permission: "1",
        },
      },
      {
        path: "/setting/special-filter",
        component: lazy(() => import("../pages/Settings/SpecialFilter")),
        meta: {
          title: "Maxsus filterlar",
          icon: "",
          permission: "1",
        },
      },
      {
        path: "/setting/facilities",
        component: lazy(() => import("../pages/Settings/Facilities")),
        meta: {
          title: "Qo'shimcha qulayliklar",
          icon: "",
          permission: "1",
        },
      },
      {
        path: "/setting/tour-category",
        component: lazy(() => import("../pages/Settings/TourCategory")),
        meta: {
          title: "Tur kategoriya",
          icon: "",
          permission: "1",
        },
      },
      {
        path: "/setting/services",
        component: lazy(() => import("../pages/Settings/Services")),
        meta: {
          title: "Xizmatlar",
          icon: "",
          permission: "1",
        },
      },
      {
        path: "/setting/excursion-category",
        component: lazy(() => import("../pages/Settings/ExcursionCategory")),
        meta: {
          title: "Ov kategoriyalar",
          icon: "",
          permission: "1",
        },
      },
      {
        path: "/setting/hunting-type",
        component: lazy(() => import("../pages/Settings/HuntingType")),
        meta: {
          title: "Ovlar turlari",
          icon: "",
          permission: "1",
        },
      },
      {
        path: "/setting/guns",
        component: lazy(() => import("../pages/Settings/Guns")),
        meta: {
          title: "Qurollar",
          icon: "",
          permission: "1",
        },
      },
      {
        path: "/setting/games",
        component: lazy(() => import("../pages/Settings/Games")),
        meta: {
          title: "O`yinlar",
          icon: "",
          permission: "1",
        },
      },
      {
        path: "/setting/animals",
        component: lazy(() => import("../pages/Settings/Animals")),
        meta: {
          title: "Hayvonlar",
          icon: "",
          permission: "1",
        },
      },
      {
        path: "/setting/static-pages",
        component: lazy(() => import("../pages/Settings/StaticPages")),
        meta: {
          title: "Statik sahifalar",
          icon: "",
          permission: "1",
        },
      },
    ],
  },
  {
    path: "/review",
    component: lazy(() => import("../pages/Review")),
    meta: {
      title: "Izohlar",
      icon: "FormOutlined",
      permission: "1",
    },
  },
  // {
  //   path: "/qr-code-reader",
  //   component: lazy(() => import("../pages/Routes/QRCodeReader")),
  //   meta: {
  //     title: "QR CODE",
  //     icon: "QrcodeOutlined",
  //     permission: "1,3,4",
  //   },
  // },
]

export default routes
