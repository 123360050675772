import React, { useEffect, useState } from "react"
import { Form, Input, Button } from "antd"
import { useHistory, useParams, useLocation } from "react-router-dom"
import { UserOutlined, LockOutlined } from "@ant-design/icons"
import { requests } from "../utils/requests"
function Login() {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  useEffect(() => {
    const token = location.search.split("?token=")[1]
    console.log(token)
    if (token) {
      setLoading(true)
      localStorage.setItem("token", token)
      localStorage.setItem("user", JSON.stringify({ role: 3 }))

      setLoading(false)
      history.push("/")
      document.location.reload()
    }
  }, [])

  const login = async (res) => {
    setLoading(true)
    try {
      const response = await requests.Auth.login(res)
      localStorage.setItem("token", response.token)
      localStorage.setItem("user", JSON.stringify(response.user))
      setLoading(false)
      history.push("/")
      document.location.reload()
    } catch (error) {
      setLoading(false)
    }
  }

  const onFinish = (values) => {
    login(values)
  }
  return (
    <div
      className="login"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "20px",
        height: "100vh",
      }}
    >
      <h1>Tizimga kirish</h1>

      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Iltimos, emailingizni kiriting!",
            },
          ]}
        >
          <Input
            size="large"
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Emailni kiriting"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: "Iltimos, parolingizni kiriting!" },
          ]}
        >
          <Input.Password
            size="large"
            prefix={<LockOutlined className="site-form-item-icon" />}
            // type='password'
            placeholder="Parolni kiriting"
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            size="large"
            // loading={loading}
          >
            Tizimga kirish
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
export default Login

const staticData = {
  access_token:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MTc2MDMxOTcsImlhdCI6MTYxNjk5ODM5NywiaXNzIjoibXVubyIsInNlc3Npb25faWQiOiI5NGJlODg5Yy0yNWRmLTQxY2MtOTkyMy04ZjYwMGJjYzA2NmQiLCJzdGF0dXMiOiJBIiwic3ViIjoiYmZmOWQ0NGEtN2RkNC00Y2M5LThjOWMtMTM5NmVhYmY3NzVmIiwidXNlcl90eXBlIjoiYWRtaW4ifQ.WwoWusGGFGRimkNqyPIxIafgpivBEps1yk1EXD_i9zY",
}
