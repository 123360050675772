import React from "react"
import { BrowserRouter, Route } from "react-router-dom"
import Routes from "./routes/routes"
import Login from "./pages/Login"
import { ConfigProvider } from "antd"
import ruRU from "antd/locale/ru_RU"

function App() {
  return (
    <ConfigProvider locale={ruRU}>
      <BrowserRouter>
        <Route path="/login" exact component={Login} />
        <Routes />
      </BrowserRouter>
    </ConfigProvider>
  )
}

export default App
