import axios_init from "./axios_init"

export const requests = {
  Auth: {
    login: (data) => axios_init.post("/public/auth/login", data),
  },
  Users: {
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/users?per_page=${limit}&page=${page}`),
    getListAll: () => axios_init.get("/admin/users/list"),
    create: (data) => axios_init.post(`/admin/users`, data),
    update: (data, id) => axios_init.post(`/admin/users/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/users/${id}`),
    updateActive: (id) => axios_init.put(`/admin/users/activate/${id}`),
  },
  Review: {
    getList: () => axios_init.get(`/admin/reviews/list`),
    index: () => axios_init.get(`/admin/reviews`),
    delete: (id) => axios_init.remove(`/admin/reviews/${id}`),
    moderate: (id, data) =>
      axios_init.post(`/admin/reviews/moderate/${id}`, data),
  },
  Routes: {
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/user-tours?per_page=${limit}&page=${page}`),
  },
  EcoObjectOrder: {
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/eco-object-orders?per_page=${limit}&page=${page}`),
    index: (number) => axios_init.get(`/admin/eco-object-orders/${number}`),
    setUsed: (id) => axios_init.put(`/admin/eco-object-orders/set-used/${id}`),
  },
  ExcursionOrder: {
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/excursion-orders?per_page=${limit}&page=${page}`),
    index: (number) => axios_init.get(`/admin/excursion-orders/${number}`),
    setUsed: (id) => axios_init.put(`/admin/excursion-orders/set-used/${id}`),
  },
  TourOrder: {
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/tour-orders?per_page=${limit}&page=${page}`),
    index: (number) => axios_init.get(`/admin/tour-orders/${number}`),
    setUsed: (id) => axios_init.put(`/admin/tour-orders/set-used/${id}`),
  },
  UserTour: {
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/user-tours?per_page=${limit}&page=${page}`),
    index: (number) => axios_init.get(`/admin/user-tours/${number}`),
    setUsed: (id) => axios_init.put(`/admin/user-tours/set-used/${id}`),
  },
  EcoObjectCategory: {
    getListAll: () => axios_init.get("/admin/eco-object-categories/list"),

    getList: ({ limit, page }) =>
      axios_init.get(
        `/admin/eco-object-categories?per_page=${limit}&page=${page}`
      ),
    create: (data) => axios_init.post(`/admin/eco-object-categories`, data),
    update: (data, id) =>
      axios_init.post(`/admin/eco-object-categories/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/eco-object-categories/${id}`),
  },
  TourCategory: {
    getListAll: () => axios_init.get("/admin/tour-categories/list"),
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/tour-categories?per_page=${limit}&page=${page}`),
    create: (data) => axios_init.post(`/admin/tour-categories`, data),
    update: (data, id) => axios_init.post(`/admin/tour-categories/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/tour-categories/${id}`),
  },

  Services: {
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/services?per_page=${limit}&page=${page}`),
    create: (data) => axios_init.post(`/admin/services`, data),
    update: (data, id) => axios_init.post(`/admin/services/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/services/${id}`),
  },
  Countries: {
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/countries?per_page=${limit}&page=${page}`),
    getListAll: () => axios_init.get("/admin/countries/list"),
    create: (data) => axios_init.post(`/admin/countries`, data),
    update: (data, id) => axios_init.post(`/admin/countries/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/countries/${id}`),
  },
  Regions: {
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/regions?per_page=${limit}&page=${page}`),
    getListAll: () => axios_init.get("/admin/regions/list"),
    create: (data) => axios_init.post(`/admin/regions`, data),
    update: (data, id) => axios_init.post(`/admin/regions/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/regions/${id}`),
  },
  Districts: {
    getListAll: () => axios_init.get("/admin/districts/list"),
    getList: ({ limit, page, region_id }) =>
      axios_init.get(
        `/admin/districts?per_page=${limit}&page=${page}&region_id=${
          region_id || ""
        }`
      ),
    create: (data) => axios_init.post(`/admin/districts`, data),
    update: (data, id) => axios_init.post(`/admin/districts/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/districts/${id}`),
  },
  Seasons: {
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/seasons?per_page=${limit}&page=${page}`),
    create: (data) => axios_init.post(`/admin/seasons`, data),
    update: (data, id) => axios_init.post(`/admin/seasons/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/seasons/${id}`),
  },
  AreaType: {
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/area-types?per_page=${limit}&page=${page}`),
    create: (data) => axios_init.post(`/admin/area-types`, data),
    update: (data, id) => axios_init.post(`/admin/area-types/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/area-types/${id}`),
  },
  Foods: {
    getListAll: () => axios_init.get("/admin/foods/list"),

    getList: ({ limit, page }) =>
      axios_init.get(`/admin/foods?per_page=${limit}&page=${page}`),
    create: (data) => axios_init.post(`/admin/foods`, data),
    update: (data, id) => axios_init.post(`/admin/foods/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/foods/${id}`),
  },
  Transports: {
    getListAll: () => axios_init.get("/admin/transports/list"),

    getList: ({ limit, page }) =>
      axios_init.get(`/admin/transports?per_page=${limit}&page=${page}`),
    create: (data) => axios_init.post(`/admin/transports`, data),
    update: (data, id) => axios_init.post(`/admin/transports/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/transports/${id}`),
  },
  Languages: {
    getListAll: () => axios_init.get("/admin/languages/list"),

    getList: ({ limit, page }) =>
      axios_init.get(`/admin/languages?per_page=${limit}&page=${page}`),
    create: (data) => axios_init.post(`/admin/languages`, data),
    update: (data, id) => axios_init.post(`/admin/languages/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/languages/${id}`),
  },
  Features: {
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/features?per_page=${limit}&page=${page}`),
    create: (data) => axios_init.post(`/admin/features`, data),
    update: (data, id) => axios_init.post(`/admin/features/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/features/${id}`),
  },
  FloraFauna: {
    getListAll: () => axios_init.get("/admin/environments/list"),
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/environments?per_page=${limit}&page=${page}`),
    create: (data) => axios_init.post(`/admin/environments`, data),
    update: (data, id) => axios_init.post(`/admin/environments/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/environments/${id}`),
  },
  Activity: {
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/activities?per_page=${limit}&page=${page}`),
    create: (data) => axios_init.post(`/admin/activities`, data),
    update: (data, id) => axios_init.post(`/admin/activities/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/activities/${id}`),
  },
  SpecialFilter: {
    getListAll: () => axios_init.get("/admin/special-filters/list"),
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/special-filters?per_page=${limit}&page=${page}`),
    create: (data) => axios_init.post(`/admin/special-filters`, data),
    update: (data, id) => axios_init.post(`/admin/special-filters/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/special-filters/${id}`),
  },
  Forestry: {
    getListAll: () => axios_init.get("/admin/forestries/list"),
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/forestries?per_page=${limit}&page=${page}`),
    create: (data) => axios_init.post(`/admin/forestries`, data),
    update: (data, id) => axios_init.post(`/admin/forestries/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/forestries/${id}`),
  },
  Facilities: {
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/facilities?per_page=${limit}&page=${page}`),
    create: (data) => axios_init.post(`/admin/facilities`, data),
    update: (data, id) => axios_init.post(`/admin/facilities/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/facilities/${id}`),
  },
  EcoObject: {
    getListAll: ({ forestry_id = "" }) =>
      axios_init.get(
        `/admin/eco-objects/list?forestry_id=${forestry_id || ""}`
      ),

    getList: ({ limit, page }) =>
      axios_init.get(`/admin/eco-objects?per_page=${limit}&page=${page}`),
    view: (id) => axios_init.get(`/admin${id}`),
    create: (data) => axios_init.post(`/admin/eco-objects`, data),
    update: (data, id) => axios_init.post(`/admin/eco-objects/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/eco-objects/${id}`),
    fileUpload: (data) => axios_init.post(`/admin/eco-objects/upload`, data),
    moderate: (data, id) =>
      axios_init.post(`/admin/eco-objects/moderate/${id}`, data),
  },
  ExcursionCategory: {
    getListAll: () => axios_init.get("/admin/excursion-categories/list"),
    getList: ({ limit, page }) =>
      axios_init.get(
        `/admin/excursion-categories?per_page=${limit}&page=${page}`
      ),
    create: (data) => axios_init.post(`/admin/excursion-categories`, data),
    update: (data, id) =>
      axios_init.post(`/admin/excursion-categories/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/excursion-categories/${id}`),
  },
  StaticPages: {
    getListAll: () => axios_init.get("/admin/static-pages/list"),
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/static-pages?per_page=${limit}&page=${page}`),
    create: (data) => axios_init.post(`/admin/static-pages`, data),
    update: (data, id) => axios_init.post(`/admin/static-pages/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/static-pages/${id}`),
  },
  HuntingType: {
    getListAll: () => axios_init.get("/admin/hunting-types/list"),
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/hunting-types?per_page=${limit}&page=${page}`),
    create: (data) => axios_init.post(`/admin/hunting-types`, data),
    update: (data, id) => axios_init.post(`/admin/hunting-types/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/hunting-types/${id}`),
  },
  Guns: {
    getListAll: () => axios_init.get("/admin/guns/list"),
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/guns?per_page=${limit}&page=${page}`),
    create: (data) => axios_init.post(`/admin/guns`, data),
    update: (data, id) => axios_init.post(`/admin/guns/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/guns/${id}`),
  },
  Games: {
    getListAll: () => axios_init.get("/admin/games/list"),
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/games?per_page=${limit}&page=${page}`),
    create: (data) => axios_init.post(`/admin/games`, data),
    update: (data, id) => axios_init.post(`/admin/games/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/games/${id}`),
  },
  Animals: {
    getListAll: () => axios_init.get("/admin/animals/list"),
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/animals?per_page=${limit}&page=${page}`),
    create: (data) => axios_init.post(`/admin/animals`, data),
    update: (data, id) => axios_init.post(`/admin/animals/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/animals/${id}`),
  },
  Movements: {
    getListAll: () => axios_init.get("/admin/movements/list"),
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/movements?per_page=${limit}&page=${page}`),
    create: (data) => axios_init.post(`/admin/movements`, data),
    update: (data, id) => axios_init.post(`/admin/movements/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/movements/${id}`),
  },
  Placements: {
    getListAll: () => axios_init.get("/admin/placements/list"),
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/placements?per_page=${limit}&page=${page}`),
    create: (data) => axios_init.post(`/admin/placements`, data),
    update: (data, id) => axios_init.post(`/admin/placements/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/placements/${id}`),
  },
  Privileges: {
    getListAll: () => axios_init.get("/admin/privileges/list"),
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/privileges?per_page=${limit}&page=${page}`),
    create: (data) => axios_init.post(`/admin/privileges`, data),
    update: (data, id) => axios_init.post(`/admin/privileges/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/privileges/${id}`),
  },
  AdditionalService: {
    getListAll: () => axios_init.get("/admin/additional-services/list"),
    getList: ({ limit, page }) =>
      axios_init.get(
        `/admin/additional-services?per_page=${limit}&page=${page}`
      ),
    create: (data) => axios_init.post(`/admin/additional-services`, data),
    update: (data, id) =>
      axios_init.post(`/admin/additional-services/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/additional-services/${id}`),
    moderate: (data, id) =>
      axios_init.post(`/admin/additional-services/moderate/${id}`, data),
  },
  VisitReason: {
    getListAll: () => axios_init.get("/admin/visit-reasons/list"),
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/visit-reasons?per_page=${limit}&page=${page}`),
    create: (data) => axios_init.post(`/admin/visit-reasons`, data),
    update: (data, id) => axios_init.post(`/admin/visit-reasons/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/visit-reasons/${id}`),
  },
  Excursion: {
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/excursions?per_page=${limit}&page=${page}`),
    view: (id) => axios_init.get(`/admin/excursions/${id}`),
    create: (data) => axios_init.post(`/admin/excursions`, data),
    update: (data, id) => axios_init.post(`/admin/excursions/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/excursions/${id}`),
    fileUpload: (data) => axios_init.post(`/admin/excursions/upload`, data),
    moderate: (data, id) =>
      axios_init.post(`/admin/excursions/moderate/${id}`, data),
  },
  Tour: {
    getList: ({ limit, page }) =>
      axios_init.get(`/admin/tours?per_page=${limit}&page=${page}`),
    view: (id) => axios_init.get(`/admin/tours/${id}`),
    create: (data) => axios_init.post(`/admin/tours`, data),
    update: (data, id) => axios_init.post(`/admin/tours/${id}`, data),
    delete: (id) => axios_init.remove(`/admin/tours/${id}`),
    fileUpload: (data) => axios_init.post(`/admin/tours/upload`, data),
    moderate: (data, id) =>
      axios_init.post(`/admin/tours/moderate/${id}`, data),
  },
}
